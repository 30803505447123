<template>
  <ContentItems
    :title="$t('components.admin.listOfApplications')"
    :list="list"
    :skeletonList="skeletonList"
    :loading="loading"
    :filter="filter"
    :count="count"
    :sekeleton="sekeleton"
    @page="$emit('page', $event)"
    #default="{item, idx}"
  >
    <ScriptItem
      class="mb-1-a"
      :menu-direction="idx === lastIdx ? 'top' : 'bottom'"
      :data="parseItemData(item, idx)"
      @remove="$emit('remove', item)"
      @edit="$emit('edit', item)"
    />
  </ContentItems>
</template>
<script>
import ContentItems from "./ContentItems";
import ScriptItem from "./ScriptItem";

export default {
    name: "ApplicationContent",
    components: {ScriptItem, ContentItems},
    props: {
        list: Array,
        skeletonList: Array,
        loading: Boolean,
        filter: Object,
        count: Number,
        parseItemData: Function,
        sekeleton: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        lastIdx() {
            return this.list.length - 1;
        }
    }
};
</script>
