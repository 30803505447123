<template>
    <div class="app-admin-page application-page">
        <ApplicationHeader name="scriptCategory.list" :count="count" add @click="onFilterClick"/>
        <ApplicationContent v-bind="contentAttrs" v-on="contentListeners" sekeleton :skeletonList="skeletonList"/>
        <ApplicationModal v-if="modalVisible" v-bind="modalAttributes" v-on="modalListeners" icon="speech-bubble" name="scriptCategory.add" ref="modal"/>
    </div>
</template>
<script>
import {getApplicationDefaultMixin, getApplicationModalMixin, getApplicationFilterMixin} from "../../mixins/ScriptPage";
import {
    ContentLoader,
    FormInputElement,
    FormInputList, ModalController,
    ModalFormController,
    ScriptPage
} from "../../services/ScriptPage";
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import ApplicationContent from "../../components/admin/ApplicationContent";
import ApplicationModal from "../../components/modals/ApplicationModal";
import {CONTENT_ITEMS_SIZES} from "../../components/admin/ContentItems";

class CategoryContentLoader extends ContentLoader {
    constructor() {
        super('/script-categories');
    }
}

const filter = {
    skip: 0,
    limit: 20
}

export default {
    name: "ScriptCategory",
    components: {
        ApplicationModal,
        ApplicationContent,
        ApplicationHeader
    },
    mixins: [getApplicationDefaultMixin(
        new ScriptPage(
            new CategoryContentLoader(),
            filter,
            new ModalFormController(
                new FormInputList('1', '', '', '', [
                    new FormInputElement('id', 'common.table.number', '', 'hidden'),
                    new FormInputElement('name', 'common.table.nameLong', '', 'input', null, [['required']]),
                    new FormInputElement('slug', 'common.table.category', '', 'select', [], [['required']]),
                    new FormInputElement('isChat', 'common.table.categoryForChat', false, 'switch'),
                ])
            ),
            new ModalController()
        )),
        getApplicationFilterMixin(filter),
        getApplicationModalMixin()
    ],
    data() {
        return {
            links: [
                { value: "select-one", name: "scriptCategory.client.choose" },
                { value: "known", name: "scriptCategory.client.known" },
                { value: "unknown", name: "scriptCategory.client.unknown" },
                { value: "bye", name: "scriptCategory.client.bye" },
                { value: "create-app", name: "scriptCategory.client.create" },
            ],
        }
    },
    mounted() {
        this.page.setFormRenderElement({
            items: this.links
        }, 'slug');
    },
    methods: {
        parseItemData(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    value: (idx + 1),
                    size: CONTENT_ITEMS_SIZES[0],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.name',
                    value: item.name,
                    size: CONTENT_ITEMS_SIZES[3],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.link',
                    value: item.slug,
                    size: CONTENT_ITEMS_SIZES[2],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.forChats',
                    value: item.isChat ? 'common.yes' : 'common.no',
                    size: CONTENT_ITEMS_SIZES[2],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                }
            ]
        }
    },

    computed: {
        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    name: "",
                    skeleton: this.loading,
                })
            }
            return list
        }
    }
}
</script>
