export const getApplicationFilterMixin = (filter) => ({
    computed: {
        filter() {
            return Object.entries(filter).reduce((acc, [key]) => ({...acc, [key]: this[key]}), {});
        },
        filterAttrs() {
            return {
                filterItems: this.filterItems,
                filter: this.filter
            }
        },
        filterListeners() {
            return {
                click: this.onFilterClick,
                filter: this.onFilterInput
            }
        }
    },
    methods: {
        onFilterClick(key) {
            if(key === 'add') {
                this.page.openModalAndUpdateForm();
            }
        },
        onFilterInput(key, value) {
            this.page.setFilterValueAndFetch(key, value);
        },
    }
})
export const getApplicationDefaultMixin = (page) => ({
    data() {
        return {
            page,
            ...page.getValues()
        }
    },
    computed: {
        contentAttrs() {
            return {
                list: this.list,
                count: this.count,
                loading: this.loading,
                filter: this.filter,
                parseItemData: this.parseItemData
            }
        },
        contentListeners() {
            return {
                page: this.fetchPage,
                remove: this.remove,
                edit: this.edit
            }
        }
    },
    created() {
        this.page.on('input', this.scriptPageInput);
        this.page.loadFilters();
        this.page.fetch();
    },
    beforeDestroy() {
        this.page.off('input', this.scriptPageInput);
    },
    methods: {
        transformEditData(item) {
            return item;
        },
        scriptPageInput(keys) {
            keys.forEach(key => {
                this.$set(this, key, this.page.getValue(key));
            });
        },
        fetchPage(page) {
            this.page.fetchPage(page)
        },
        remove(item) {
            this.page.remove(item.id);
        },
        edit(item) {
            this.page.openModalAndUpdateForm(this.transformEditData(item));
        },
    }
})
export const getApplicationModalMixin = () => ({
    computed: {
        modalAttributes() {
            return {
                errorMessages: this.formErrorMessages,
                renderInputs: this.formRenderElements,
                model: this.formModel,
                loading: this.createLoading
            }
        },
        modalListeners() {
            return {
                reset: this.onFormReset,
                close: this.onFormClose,
                submit: this.onFormSubmit,
                input: this.onFormInput
            }
        }
    },
    mounted() {
        this.page.initModalLink(() => this.$refs.modal);
    },
    methods: {
        onFilterClick(key) {
            if(key === 'add') {
                this.page.openModalAndUpdateForm();
            }
        },
        onFormReset() {
            this.page.formResetEditable();
        },
        onFormClose() {
            this.page.formClose();
        },
        onFormInput(key, value) {
            this.page.formInput(key, value);
        },
        onFormSubmit() {
            this.page.submit();
        },
    }
});
// Тот же getApplicationDefaultMixin, но упрощенный. В дальнейшем чуть поменяю логику миксинов
// Абсолютно базовый функционал без фильтров
export const productPageMixin = (page) => ({
    data() {
        return {
            page,
            count: page.getValue('count'),
            list: page.getValue('list'),
            loading: page.getValue('loading'),
        }
    },
    created() {
        this.page.on('input', this.scriptPageInput);
        this.page.fetch();
    },
    beforeDestroy() {
        this.page.off('input', this.scriptPageInput);
    },
    methods: {
        scriptPageInput(keys) {
            keys.forEach(key => {
                this.$set(this, key, this.page.getValue(key));
            });
        },
    }
});
