<template>
    <PageLoader v-if="loading && !sekeleton"/>
    <div v-else>
        <div v-if="listOrSkeleton.length">
            <div v-for="(item, idx) in listOrSkeleton" :key="item.id || 'b' + idx">
                <slot :item="item" :idx="idx"/>
            </div>
        </div>
        <div v-else class="empty-title">{{ $t("components.common.nothingFound") }}</div>
        <div v-if="paginationVisible && count > filter.limit" class="d-flex justify-content-between align-items-center mt-20">
            {{ filter.skip + 1 }}-{{ filter.skip + list.length }} {{ $t("components.common.from") }} {{ count }}
            <b-pagination class="pagination m-0" next-class="prev" prev-class='next' prev-text="" next-text=""
                          :value="page" :total-rows="count" :per-page="filter.limit" @input="$emit('page', $event)"
            />
        </div>
    </div>
</template>
<script>
import PageLoader from "../partials/PageLoader";
export const CONTENT_ITEMS_SIZES = ['extra-small', 'medium', 'small', 'fluid', 'double-small']; // Разработчик опять все напутал и small в верстке больше medium

export default {
    name: 'ContentItems',
    components: {PageLoader},
    props: {
        loading: Boolean,
        count: Number,
        filter: Object,
        list: Array,
        skeletonList: Array,
        sekeleton: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        paginationVisible() {
            return this.filter
        },
        page() {
            if(!this.filter) return 0;
            return Math.ceil(this.filter.skip / this.filter.limit) + 1;
        },
        listOrSkeleton() {
            if (this.loading && this.skeletonList) {
                return this.skeletonList
            }
            return this.list
        }
    }
}
</script>
