<template>
    <div class="script-item" @click="$emit('click:wrapper', $event)">
        <div v-for="item in data" class="script-item__info-wrap" :class="['script-item__info-wrap--' + item.size]">
            <div class="script-item__name" v-if="item.name">{{ $t(item.name) }}</div>
            <div class="script-item__value tw-pr-3" :class="{'!tw-mt-0': !item.name}">
                <slot :name="'item.' + item.key" :item="item">
                    <template v-if="item.skeleton">
                        <div class="skeleton" :style="{height: item.skeletonHeight || '22px'}"></div>
                    </template>
                    <template v-else-if="item.type === 'checkbox'">
                        <AppCheckboxA
                            :val="item.value ? 1 : 0"
                            :value="1"
                            @input="$emit('checked', $event)"
                            :theme="checkboxAttrs.theme"
                        ></AppCheckboxA>
                    </template>
                    <template v-else>
                        <div v-if="item.tag" class="script-item__tag status-button" :class="{'status-button--theme-red': !item.active}">{{ $t(item.value) }}</div>
                        <div v-else :class="item.valueClass">{{ getTranslationOrKey(item.value) }}</div>
                    </template>
                </slot>
            </div>
        </div>
        <div class="script-item__actions">
            <slot name="additional-actions" />
            <div class="script-item__button-wrap" v-clickaway="close" v-if="actions.length">
                <AppButton class="script-item__button" theme="primary-outline" square size="small" @click.stop="toggle">
                    <AppIcon icon="dots-horizontal"/>
                </AppButton>
                <transition :name="menuTransition">
                    <div v-show="opened" class="script-item__menu-actions" :class="'script-item__menu-actions--direction-' + menuDirection">
                        <div v-if="actions.includes('edit')" class="script-item__menu-item" @click="onEdit">
                            <AppIcon icon="edit-alt"/>
                            {{ $t("common.table.edit") }}
                        </div>
                        <div v-if="actions.includes('upload-contacts')" class="script-item__menu-item" @click="onUploadContacts">
                            <AppIcon icon="file"/>
                            {{ $t("common.table.uploadContacts") }}
                        </div>
                        <div v-if="actions.includes('copy')" class="script-item__menu-item" @click="onCopy">
                            <AppIcon icon="copy"/>
                          {{ $t("common.table.copy") }}
                        </div>
                        <div v-if="actions.includes('remove')" class="script-item__menu-item" @click="onRemove">
                            <AppIcon icon="trash-alt"/>
                          {{ $t("common.table.delete") }}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
import AppCheckboxA from "../partials/AppCheckboxA";

import clickaway from "../../directives/clickaway";
import {translationExists} from "@/mixins/local/translationExists.mixin";
import {EventBus} from "@/helpers/eventBus";

export default {
    name: 'ScriptItem',
    components: {AppIcon, AppButton, AppCheckboxA},
    directives: {clickaway},
    mixins: [translationExists],
    props: {
        data: Array,
        actions: {
            type: Array,
            default: () => ['remove', 'edit']
        },
        menuDirection: {
            type: String,
            default: 'bottom'
        },
        checkboxAttrs: {
            type: Object,
            required: false,
            default: () => ({
                theme: 'square'
            })
        }
    },
    data() {
        return {
            opened: false
        }
    },
    computed: {
        menuTransition() {
            return this.menuDirection === 'bottom' ? 'dialer' : 'dialer-reversed';
        }
    },
    mounted() {
      EventBus.$on('close-all-menus', this.close);
    },
  methods: {
        toggle() {
            if(this.opened) {
                this.close();
            } else {
                EventBus.$emit('close-all-menus');
                this.open();
            }
        },
        open() {
            this.opened = true;
        },
        close() {
            this.opened = false;
        },
        event(e) {
            if(!this.actions.includes(e)) return;
            this.close();
            this.$emit(e);
        },
        onRemove() {
            this.event('remove');
        },
        onEdit() {
            this.event('edit');
        },
        onCopy(){
            this.event('copy');
        },
        onUploadContacts() {
            this.event("upload-contacts")
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.script-item {
    padding: 16px 32px 18px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    &__name {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #949494;
    }
    &__value {
        margin-top: 9px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        word-break: break-word;
    }
    &__tag {
        margin-top: 6px;
    }
    &__button {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -5px;
            right: -5px;
            bottom: -5px;
            left: -5px;
        }
    }
    &__button-wrap {
        margin-left: 12px;
        position: relative;
    }
    &__actions {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
    &__menu-actions {
        z-index: 4;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 0px 2.79304px 5.32008px rgba(0, 0, 0, 0.0503198), 0px 1.16225px 2.21381px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        padding: 4px;
        position: absolute;
        top: 38px;
        right: 0;
        &--direction-top {
            top: auto;
            bottom: 38px;
        }
    }
    &__menu-item {
        transition: background-color .25s;
        cursor: pointer;
        user-select: none;
        padding: 10px 20px 10px 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.005em;
        border-radius: 8px;
        text-wrap: nowrap;
        svg {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            color: #949494;
        }
        &:hover {
            background: #EDF2FF;
        }
    }
    &__info-wrap {
        &--fluid {
            width: 0;
            flex-grow: 1;
        }
        &--extra-small {
            width: 70px;
        }
        &--small {
            width: 280px;
        }
        &--double-small {
            width: 560px;
        }
        &--medium {
            width: 194px;
        }
    }
}
</style>
